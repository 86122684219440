import { useCallback } from "react";
import { useAccount } from "wagmi";
import type { Address } from "viem";
import { gql } from "graphql-request";
import { MutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RQueryOptions, RQueryOptionsForFetcher, getAuthCookie, graphql, isAuthorized } from "@looksrare/utils";
import { SuccessPayload } from "@looksrare/nfts";
import { graphql as generatedGql } from "@looksrare/yolo-games-gql-typegen";
import type { User } from "@/types";
import { getAuthHeader } from "@/utils";
import { type UserFragment, userFragment } from "./fragments";

interface UserResponse {
  user: UserFragment;
}

const userQuery = gql`
  query GetUser($address: Address!) {
    user(address: $address) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const userKeys = {
  user: (address: Address) => ["user", address],
  hasPendingOrConfirmedReferrer: (address: Address) => ["user", "hasPendingOrConfirmedReferrer", address],
  boostProgress: (address: Address) => ["user", "boostProgress", address],
  referralCode: ({ isUserAuthorized, address }: { isUserAuthorized: boolean; address?: Address }) => [
    "user",
    "referralCode",
    isUserAuthorized,
    address,
  ],
};

export const useUser = (address: Address, options?: RQueryOptions<User>) => {
  return useQuery({
    queryKey: userKeys.user(address),
    queryFn: async () => {
      const response = await graphql<UserResponse>({ query: userQuery, params: { address } });
      return response.user;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useInvalidateUser = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (address: Address) => {
      return queryClient.invalidateQueries({ queryKey: userKeys.user(address) });
    },
    [queryClient]
  );
};

const referralCodeQuery = gql`
  query GetReferralCode($address: Address!) {
    user(address: $address) {
      referralCode
    }
  }
`;

/**
 * This will return null if the user has never logged in
 */
export const useReferralCode = (options?: RQueryOptions<string | null>) => {
  const { address } = useAccount();
  const isUserAuthorized = isAuthorized(address);
  const authCookie = getAuthCookie(address!);

  const requestHeaders = {
    Authorization: `Bearer ${authCookie}`,
  };

  return useQuery({
    queryKey: userKeys.referralCode({ address, isUserAuthorized }),
    queryFn: async () => {
      const response = await graphql<{ user: { referralCode: string } }>({
        query: referralCodeQuery,
        params: { address },
        requestHeaders,
      });
      return response.user.referralCode;
    },
    enabled: !!address,
    ...options,
  });
};

// @todo-yg remove once testing is over as it puts unnecessary load on the backend api.
// @note we fetch the primaryReferrer address even though we don't use it currently. Helpful for QA
const userReferrerQuery = gql`
  query UserReferrer($address: Address!) {
    user(address: $address) {
      referral {
        hasPendingOrConfirmedReferrer
        primaryReferrer {
          address
        }
      }
    }
  }
`;

interface UserReferrerResponse {
  user?: {
    referral?: {
      hasPendingOrConfirmedReferrer?: boolean;
      primaryReferrer?: {
        address: string;
      };
    };
  };
}

interface UseHasUserReferrerReturn {
  hasPendingOrConfirmedReferrer?: boolean;
  primaryReferrer?: { address: string } | null;
}
/**
 * This will return null if the user has never logged in
 */
export const useHasUserReferrer = (address: Address, options?: RQueryOptions<UseHasUserReferrerReturn>) => {
  return useQuery({
    queryKey: userKeys.hasPendingOrConfirmedReferrer(address),
    queryFn: async () => {
      const response = await graphql<UserReferrerResponse>({
        query: userReferrerQuery,
        params: { address },
      });
      return response.user?.referral ?? { hasPendingOrConfirmedReferrer: false, primaryReferrer: null };
    },
    enabled: !!address,
    ...options,
  });
};

const askSelfTimeoutQuery = gql`
  mutation AskSelfTimeout($timeoutUntil: DateTime!) {
    askSelfTimeout(timeoutUntil: $timeoutUntil) {
      success
    }
  }
`;

export const useAskSelfTimeout = (options?: MutationOptions<{ askSelfTimeout: SuccessPayload }, unknown, string>) => {
  const { address } = useAccount();
  return useMutation({
    mutationFn: (timeoutUntil) => {
      const headers = getAuthHeader(address);
      return graphql({
        query: askSelfTimeoutQuery,
        params: { timeoutUntil },
        requestHeaders: headers,
      });
    },
    ...options,
  });
};

const getUserMidSeasonRewards = async (address: Address) => {
  const userMidSeasonRewardsQuery = generatedGql(/* GraphQL */ `
    query UserMidSeasonRewards($address: Address!) {
      user(address: $address) {
        midSeasonGoldDistribution {
          gamesGold
          dropletsGold
          yYoloGold
        }
      }
    }
  `);

  const res = await graphql({ query: userMidSeasonRewardsQuery, params: { address } });
  return res.user?.midSeasonGoldDistribution;
};

export const useUserMidSeasonRewards = (options?: RQueryOptionsForFetcher<typeof getUserMidSeasonRewards>) => {
  const { address } = useAccount();

  return useQuery({
    queryKey: ["user", "mid-season-rewards", address],
    queryFn: () => getUserMidSeasonRewards(address!),
    enabled: !!address,
    ...options,
  });
};
